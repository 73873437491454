"use client"
import React, { useRef } from 'react';
// import { gql } from '@apollo/client';

// import useAnaglyphEffect from '../../../hooks/useAnaglyphEffect';
// import useBox from '../../../hooks/useBox';
import useLensFlare from '../../../hooks/useLensFlare';
// import useStereoEffect from '../../../hooks/useStereoEffect';
// import { IUser } from '../../../types';
import AppLoader from '../../ui/loader/AppLoader';
import AppTagline from './tagline/AppTagline';
import AppSocialIcon from '../../ui/icon/AppSocialIcon';

// interface IUseQuery {
//   user: IUser;
// }

// const GET_USER = gql`
//   query User($email: String) {
//     user(where: { email: $email }) {
//       id
//       name
//       job
//       bio
//       email
//     }
//   }
// `;

interface AppHeaderProps { }

const AppHeader: React.FC<AppHeaderProps> = ({ }) => {
  const elementRef = useRef<HTMLElement|null>(null);


  useLensFlare(elementRef);
  // const elementRef = useBox();

  return (
    <header
      ref={elementRef}
      className={`overflow-hidden h-screen is-dark-background`}
    >
      {false ? (
        <div className="absolute text-center -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 py-4 w-full z-10">
          <AppLoader />
        </div>
      ) : (
        <>
          <AppTagline />
          <div className="absolute text-center -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 p-4 w-full z-10">
            <div tabIndex={0}>
              <p className="text-bwhite text-[16px] font-medium">
                {/* Hi, My name is {data?.user.name || 'Samson Iyanda'}, and I&apos;m
              a */}
                Highly Skilled
              </p>
             
              <div className="border-2 border-solid border-bwhite my-4 mx-auto max-w-[600px] p-[11px] w-full">
                <h3 className="text-bwhite text-[33px] font-[900] tracking-[2px] leading-[33px]">
                  Software Developer
                </h3>
              </div>
              <p className="text-bwhite text-[16px] font-medium">
                Specialised in Web Development Technologies
              </p>
            </div>
           
            <div className="mt-[50px]">
              <ul className="flex justify-center flex-wrap">
                <li>
                  <AppSocialIcon
                    icon="github"
                    uri="https://github.com/samcyn"
                    hasBackground={false}
                  />
                </li>
                <li className="mx-8">
                  <AppSocialIcon
                    icon="linkedin"
                    uri="https://www.linkedin.com/in/samson-iyanda-36641b22/"
                    hasBackground={false}
                  />
                </li>
                <li>
                  <AppSocialIcon
                    uri="https://twitter.com/samcyn007"
                    icon="twitter"
                    hasBackground={false}
                  />
                </li>
              </ul>
            </div>
          </div>
        </>
      )}
    </header>
  );
};

export default AppHeader;
