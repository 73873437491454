import React from 'react';
import Image from 'next/image';

interface AppLogoProps {
}

const AppLogo: React.FC<AppLogoProps> = ({ }) => {
  return (
    <div className="w-[47px] h-[47px] rounded-full border border-solid border-bwhite mr-4 relative overflow-hidden">
      <Image src="/images/jide.jpeg" alt="photo" loading="lazy"
        sizes="(max-width: 45px) 45px, (max-width: 45px) 45px, 45px"
        fill />
    </div>
  );
}

export default AppLogo;