import React from 'react'
import styles from './AppLoader.module.scss';

interface AppLoaderProps {
  isDark?: boolean
}

const AppLoader = ({
  isDark
}: AppLoaderProps) => {
  return (
    <div className={`loader ${isDark ? 'bg-dark' : ''}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default AppLoader;