"use client"
import React, { Suspense } from 'react';
import Link from 'next/link';

import AppLogo from '../../logo/AppLogo';

interface AppTaglineProps {
  isDark?: boolean;
}

const AppDrawer = React.lazy(() => import('../../../ui/drawer/AppDrawer'));

const AppTagline: React.FC<AppTaglineProps> = ({ isDark }) => {

  const computedClass = `
    py-4 absolute top-0 left-0 
    right-0 w-full header-height 
    flex justify-between items-center 
    z-[900] ${isDark ? 'is-dark-background' : ''}`;
  return (
    <div className={computedClass}>
      <div className="container flex justify-between">
        <Link href="/" className="flex items-center z-10" passHref>
          <AppLogo />
          <span className="uppercase font-[900] text-[12px] tracking-[1px] !text-bwhite">Samson Iyanda</span>
        </Link>

        <div className="is-flex is-align-items-center">
          {/* <Link href="/blog" passHref>
            <button
              className="button is-brand is-inverted is-outlined w-100"
              type="submit"
            >
              Blog
            </button>
          </Link> */}
          <Suspense fallback="loading...">
            <AppDrawer
              width={750}
              activator={({ toggleVisible }) => (
                <>
                  <button
                    className="bg-transparent border-white text-bwhite border cursor-pointer justify-center text-center whitespace-nowrap w-full hidden md:flex px-4 py-[7px]"
                    type="submit"
                    onClick={toggleVisible}
                  >
                    RESUME
                  </button>
                  <a
                    className="bg-transparent border-white text-bwhite border cursor-pointer justify-center text-center whitespace-nowrap w-full flex md:hidden px-4 py-[7px]"
                    href='/pdf/resume.pdf'
                    download
                  >
                    RESUME
                  </a>
                </>
              )}
            >
              <embed
                className="h-screen"
                type="application/pdf"
                src="/pdf/resume.pdf"
                width="100%"
              />
            </AppDrawer>
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default AppTagline;
